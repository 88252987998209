<template>
  <div class="fondoprincipal">
    <div class="row banner-principal">
      <div class="col-md-3 offset-md-4 col-sm-12 container-banner-info login-content">
        <h2 class="title-principal-banner">Be part of our first users.</h2>
        <h3 class="subtitle-principal-banner">Sing Up</h3>
      </div>
      <div class="col-md-3 ocultarpequeno">
        <img class="img-principal-banner" alt="logo" src="../assets/phone.png">
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="col-md-4 offset-md-4">
        <h2 class="title-principal-banner-blue">Sing Up</h2>
        <RegisterClientForm :language="language" class="select-categories"></RegisterClientForm>
        <!--<button class="btn-send">
          Send / Enviar
        </button>-->
    </div>
</div>
</template>

<script>
import RegisterClientForm from '../components/firstUsers/RegisterClientForm.vue';

export default {
  props:['language'],
  components:{ RegisterClientForm },
  name:'FirstUsers',
  watch:{
    language: function(){
      console.log("cambiando de idioma");
      console.log(this.language);
    }
  }
}
</script>

<style>

  .banner-principal {
    background-color: #c9bab3;
    height: 350px;
  }

  .container-banner-info{
    margin-top: 100px;
    text-align: left;
  }

  .img-principal-banner{
    margin-left: -150px;
    margin-top: 60px;
    height: 300px;
  }

  .select-categories{
    margin-bottom: 50px;
  }

  @media screen and (max-width: 770px){
      
      /*.img{
        display: none;
      }*/

      .ocultarpequeno{
        display: none;
      }

      .login-content{
        justify-content: center;
      }

}

  
</style>