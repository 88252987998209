//import { BehaviorSubject } from 'rxjs';
import axios from 'axios';

const baseUrl = `${process.env.VUE_APP_API_URL}/api/public/categories`;
//const categorySubject = new BehaviorSubject(null);

export const categoryService = {
    getAll
};

export function getAll() {
    console.log("buscando experiencias");
    return axios.get(`${baseUrl}`, {})
        .then(response => response.data);
}
