<template>
  <div class="container">
    <div class="row">
        <div class="col-md-6 mb-3">
            <input type="text" v-model="firstName" class="form-control inputredondeado" placeholder="Name / Nombre">
        </div>
        <div class="col-md-6 mb-3">
            <input type="text" v-model="lastName" class="form-control inputredondeado" placeholder="Last name / Apellido">
        </div>
        <div class="col-md-12 mb-3">
            <input type="email" v-model="email" class="form-control inputredondeado" placeholder="Email">
        </div>
        <div class="col-md-12 mb-3">
            <input type="email" v-model="email2" class="form-control inputredondeado" placeholder="Confirm / Confirmar Email">
        </div>
        <div class="col-md-12 mb-3">
            <input type="password" v-model="password" class="form-control inputredondeado" placeholder="Password / Contraseña">
        </div>
        <div class="col-md-12 mb-3">
            <input type="password" v-model="password2" class="form-control inputredondeado" placeholder="Confirm Password / Confirma Contraseña">
        </div>
        <div class="col-md-12 mb-3 text-left">
            <label for="" class="title-form">Date Of Birth / Fecha De Nacimiento</label>
            <input type="date" v-model="birthdate" class="form-control inputredondeado">
            <!--<div class="row">
                <div class="col">
                    <select name="" id="" class="form-select inputredondeado">
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                    </select>
                </div>
                <div class="col">
                    <select name="" id="" class="form-select inputredondeado">
                        <option value="enero">enero</option>
                        <option value="febrero">febrero</option>
                        <option value="marzo">marzo</option>
                        <option value="april">april</option>
                    </select>
                </div>
                <div class="col">
                    <select name="" id="" class="form-select inputredondeado">
                        <option value="1990">1990</option>
                        <option value="1991">1991</option>
                    </select>
                </div>
            </div>-->
        </div>
        <div class="col-md-12 mb-3 text-left">
            <label for="" class="title-form">Gender / Genero</label>
            <select v-model="gender" name="" id="" class="form-select inputredondeado">
                        <option class="" value="FEMININE">Female</option>
                        <option class="" value="MASCULINE">Masculine</option>
                        <option class="" value="NEUTER">Other</option>
            </select>
        </div>
        <div class="col-md-12 mb-3 text-left">
            <div class="row">
                <div class="col">
                <input type="text" v-model="country" placeholder="Country / País" class="form-control inputredondeado">
                </div>
                <div class="col">
                    <input type="text" v-model="location"  placeholder="State / Estado" class="form-control inputredondeado">
                </div>
                <div class="col">
                    <input type="text" v-model="city" placeholder="City / Ciudad" class="form-control inputredondeado">
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 text-left">
        <label for="" class="title-form">Interests / Intereses</label>
    </div>
    <div class="accordion" id="accordionExample">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button inputredondeado" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Select categories ...
            </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body">
                <div v-for="category in categories" v-bind:key="category.id" v-bind:value="category.id" class="row categoryBox">
                    <div class="col-md-12 title-container-options">
                        <span v-if="language== 'ENGLISH'" class="title-box-option">{{category.name.ENGLISH}}</span>
                        <span v-else class="title-box-option">{{category.name.SPANISH}}</span>
                    </div>
                    <div v-for="subcategory in category.subcategory" v-bind:key="subcategory.id" 
                    v-bind:value="subcategory.id"  class="col-md-6 optionsBox2">
                            <input class="form-check-input" type="checkbox" value="" id="" v-on:click="llenarIntereses(subcategory.id)">
                            <label class="label-checkbox" for="">
                                {{subcategory.name.ENGLISH}}
                            </label>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="select-categories">

    </div>
    <button :disabled="enviando" @click="crearUsuario" class="btn-send separar">
        <label v-if="!enviando">Send / Enviar</label>
        <span v-if="enviando" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <label v-if="enviando">Enviando...</label>
      </button>
  </div>
</template>

<script>
    import {categoryService} from '@/services/categories.service.js';
    import {userService} from '@/services/users.service.js';
    import Swal from 'sweetalert2';
    export default {
        props:['language'],
        name:'RegisterClientForm',
        data() {
            return {
                categories:[],
                backupCategories:[],
                firstName:'',
                lastName:'',
                email:'',
                email2:'',
                password:'',
                password2:'',
                country:'',
                city:'',
                location:'',
                gender:'',
                birthdate:'',
                interests:[],
                enviando:false
            }
        },
        created() {
            categoryService.getAll()
                .then(x => {
                    this.categories = x
                    this.backupCategories = x
                });
        },
        watch:{
            language: function(){
                console.log(this.language);
            }
        },
        methods: {
            llenarIntereses: function(idInteres){
                if(this.interests.includes(idInteres)){
                    const index=this.interests.indexOf(idInteres);
                    if(index>-1){
                        this.interests.splice(index,1);
                    }
                }else{
                    this.interests.push(idInteres);
                }
            },
            limpiarCampos(){
                this.categories=this.backupCategories,
                this.firstName='',
                this.lastName='',
                this.email='',
                this.email2='',
                this.password='',
                this.password2='',
                this.country='',
                this.city='',
                this.location='',
                this.gender='',
                this.birthdate='',
                this.interests=[]
            },
            crearUsuario(){
                var request={
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    password: this.password,
                    country: this.country,
                    city: this.city,
                    location: this.location,
                    gender: this.gender,
                    birthdate: this.birthdate,
                    appOS: 'Web',
                    interests: this.interests
                };
                this.enviando=true;
                userService.create(request).then(() => {
                    this.enviando=false;
                    Swal.fire(
              'Felicidades, ahora eres parte LetZTrav!',
              'Te enviaremos un correo para informarte sobre los siguientes pasos.',
              'success'
				);
                this.limpiarCampos();
                })
                .catch(error => {
                    this.enviando=false;
                    this.error = error;
                    this.loading = false;
                });
            },
        }
}
</script>

<style>
    .select-categories{
        margin-bottom: 50px;
    }
    .title-container-options{
        text-align: left;
    }
    .title-box-option{
        color: #002fa5;
    }
    .optionsBox2{
        text-align: left;
        /*margin-top: 15px;
        margin-bottom: 20px;*/
    }
    .categoryBox{
        /*text-align: left;*/
        margin-top: 15px;
    }
    .label-checkbox{
        margin-left: 10px;
    }
    .title-form{
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 10px;
        color: gray;
    }
    .inputredondeado{
        border-radius: 12px;
    }
</style>