//import { BehaviorSubject } from 'rxjs';
import axios from 'axios';

const baseUrl = `${process.env.VUE_APP_API_URL}/api/users`;
//const categorySubject = new BehaviorSubject(null);

export const userService = {
    create
};

export function create(user) {
    return axios.post(`${baseUrl}/registrations/pre`, user , {
        headers:{'Content-Type': 'application/json'}
      })
        .then(response => response.data).catch(function(err){
            console.log(err.response.errors);
            });
}
